import { NgModule} from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { PaginationComponent } from './pagination.component';

@NgModule({
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
  imports: [
    SharedModule
  ]
})
export class PaginationModule { }

<div id="dealerSearchForm">
  <form [formGroup]="dealerSearchForm" (ngSubmit)="searchDealers()" autocomplete=off>
    <h2>Dealership Search</h2>
    <div class='field' [ngClass]="{'error': formErrors['dealerId'] || formErrors['parent']}">
      <ion-label id="dealerIdLabel" class="search-label">Dealer ID<span class="error"></span></ion-label>
      <ion-input inputmode="numeric" id="dealerId" formControlName="dealerId"
        (keypress)="omitAlphaSpecialChar($event)" (ionBlur)="validationErrors(dealerSearchForm)"></ion-input>
      <span id="dealerIdError" class="field_note" *ngIf="formErrors['dealerId']">{{ formErrors.dealerId }}</span>
    </div>
    
    <h3>Or</h3>
    
    <div class='field' [ngClass]="{'error': formErrors['dealerName'] || formErrors['parent']}">
      <ion-label id="dealerNameLabel" class="search-label">Dealership Name<span class="error"></span></ion-label>
      <ion-input inputmode="text" id="dealerName" formControlName="dealerName"
        (ionBlur)="validationErrors(dealerSearchForm)"></ion-input>
      <span id="dealerNameError" class="field_note" *ngIf="formErrors['dealerName']">{{ formErrors.dealerName }}<br></span>
      <span id="dealerSearchFormError" class="field_note" *ngIf="formErrors['parent']">{{ formErrors.parent }}</span>
    </div>
    <div class="flex-row-right">
      <button id="dealerSearchSubmit" type="submit" class="btn" [ngClass]="{'secondary inactive': dealerSearchForm.invalid}"
        [disabled]="dealerSearchForm.invalid">Search</button>
    </div>
  </form>
</div>

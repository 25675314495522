import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(
    private userSvc: UserService,
    private router: Router) {
  }

  async canActivate() {
    await this.userSvc.canActivate();
    return true;
  }

}

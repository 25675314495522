import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { OrderModule } from 'ngx-order-pipe';
import { UnixToDateStringPipe } from './pipes/unix-to-datestring.pipe';
import { DateTimePipe } from './pipes/dateTimePipe.pipe';

@NgModule({
  declarations: [UnixToDateStringPipe, DateTimePipe],
  imports: [],
  exports: [
    NgxSpinnerModule,
    NgxPaginationModule,
    CommonModule,
    OrderModule,
    UnixToDateStringPipe,
    DateTimePipe
  ]
})
export class SharedModule { }

<ion-content>
  <div class="home">
    <header>
      <a [routerLink]="'/user-migration'">User Migration</a>
      <a [routerLink]="'/internal-user'">Create Internal User</a>
      <a [routerLink]="'/access-token'">Access Token</a>
      <a *ngIf="canUpdateManifest$ | async" [routerLink]="'/update-manifest'">Update Manifest</a>
      <a *ngIf="canUpdateHeader$ | async" [routerLink]="'/update-header-message'">Update Header Message</a>
      <a *ngIf="canReadReport$ | async" [routerLink]="'/reports'">Reports</a>
      <a *ngIf="canDoBulkUpdate$ | async" [routerLink]="'/bulkupdate-permissions'">Bulk Update</a>
      <a (click)="logout()"><i class="fas fa-sign-out-alt" aria-hidden="true"></i> Logout </a>
    </header>

    <div class="neo-search ion-padding">
      <div class="logo-container">
        <img src="../../assets/logo.png" alt="AFC NEO Logo"/>
      </div>
      <div class="user-search box">
        <app-user-search></app-user-search>
      </div>
      <div class="dealer-search box">
        <app-dealer-search></app-dealer-search>
      </div>
    </div>
  </div>
</ion-content>

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular'
import { UpdateHeaderMessageGuard } from './guards/update-header-message.guard';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';

const routes: Routes = [
  {
    path: 'callback',
    component: OktaCallbackComponent
  },
  {
    path: 'login',
    component: LoginRedirectComponent
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'logged-out',
    loadChildren: () => import('./logged-out/logged-out.module').then( m => m.LoggedOutPageModule)
  },
  {
    path: 'access-token',
    loadChildren: () => import('./access-token/access-token.module').then( m => m.AccessTokenPageModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error-routing.module').then( m => m.ErrorPageRoutingModule),
  },
  {
    path: 'dealer-search-results',
    loadChildren: () => import('./dealer-search-results/dealer-search-results.module').then( m => m.DealerSearchResultsPageModule)
  },
  {
    path: 'user-search-results',
    loadChildren: () => import('./user-search-results/user-search-results.module').then( m => m.UserSearchResultsPageModule)
  },
  {
    path: 'dealer',
    loadChildren: () => import('./dealer/dealer.module').then( m => m.DealerPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'internal-user',
    loadChildren: () => import('./internal-user/internal-user.module').then( m => m.InternalUserPageModule)
  },
  {
    path: 'user-migration',
    loadChildren: () => import('./user-migration/user-migration.module').then( m => m.UserMigrationPageModule)
  },
  {
    path: 'user-migration-search-results',
    loadChildren: () => import('./user-migration-search-results/user-migration-search-results.module').then( m => m.UserMigrationSearchResultsPageModule)
  },
  {
    path: 'user-migration-details',
    loadChildren: () => import('./user-migration-details/user-migration-details.module').then( m => m.UserMigrationDetailsPageModule)
  },
  {
    path: 'update-manifest',
    loadChildren: () => import('./update-manifest/update-manifest.module').then( m => m.UpdateManifestPageModule)
  },
  {
    path: 'update-header-message',
    loadChildren: () => import('./afcd-header-message/afcd-header-message.module').then( m => m.AFCDHeaderMessageModule),
    canActivate: [UpdateHeaderMessageGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsModule)
  },
  {
    path: 'bulkupdate-permissions',
    loadChildren: () => import('./bulkupdate-permissios/bulkupdate-permissios-routing.module').then( m => m.BulkupdatePermissiosPageRoutingModule)
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/home',
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div id="user-search">
  <form [formGroup]="userSearchForm" (ngSubmit)="searchUsers()" autocomplete=off>
  <h2>User Search</h2>
  <div class='field' [ngClass]="{'error': userSearchFormErrors['emailAddress'] || userSearchFormErrors['parent']}">
    <ion-label id="emailAddressLabel" class="search-label">Email Address<span class="error"></span></ion-label>
    <ion-input inputmode="email" id="emailAddress" formControlName="emailAddress"
    (ionBlur)="userSearchValidationErrors(userSearchForm)"></ion-input>
    <span id="emailAddressError" class="field_note" *ngIf="userSearchFormErrors['emailAddress']">{{ userSearchFormErrors.emailAddress }}<br></span>
  </div>
  <div class='field' [ngClass]="{'error': userSearchFormErrors['dealerId'] || userSearchFormErrors['parent']}">
    <ion-label id="userSearchDealerIdLabel" class="search-label">Dealer ID<span class="error"></span></ion-label>
    <ion-input inputmode="numeric" id="userSearchDealerId" formControlName="dealerId"
    (keypress)="omitAlphaSpecialChar($event)" (ionBlur)="userSearchValidationErrors(userSearchForm)"></ion-input>
    <span id="userSearchDealerIdError" class="field_note" *ngIf="userSearchFormErrors['dealerId']">{{ userSearchFormErrors.dealerId }}<br></span>
  </div>
  <div class='field' [ngClass]="{'error': userSearchFormErrors['lastName'] || userSearchFormErrors['parent']}">
    <ion-label id="lastNameLabel" class="search-label">Last Name<span class="error"></span></ion-label>
    <ion-input inputmode="text" id="lastName" formControlName="lastName"
    (ionBlur)="userSearchValidationErrors(userSearchForm)"></ion-input>
    <span id="lastNameError" class="field_note" *ngIf="userSearchFormErrors['lastName']">{{ userSearchFormErrors.lastName }}<br></span>
    <span id="userSearchFormError" class="field_note" *ngIf="userSearchFormErrors['parent']">{{ userSearchFormErrors.parent }}</span>
  </div>
  <div class="flex-row-right">
    <button id="userSearchSubmit" type="submit" class="btn" [ngClass]="{'secondary inactive': userSearchForm.invalid}"
        [disabled]="userSearchForm.invalid">Search</button>
  </div>
  </form>
</div>
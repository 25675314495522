import { Inject, Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class UpdateHeaderMessageGuard implements CanActivate {
  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public userService: UserService,
    private router: Router
  ) {}

  async canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      const canUpdateManifest =this.userService.hasTopLevelPermission('read-header');
      if (canUpdateManifest) {
        resolve(true);
      } else {
        this.router.navigateByUrl("/home");
        resolve(false);
      }
    });
  }
}

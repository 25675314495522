import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BulkupdatePermissiosPageRoutingModule } from './bulkupdate-permissios-routing.module';
import { BulkupdatePermissiosPage } from './bulkupdate-permissios.page';
import { HeaderModule } from '../core/header/header.module';
import { SharedModule } from '../shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HeaderModule,
    BulkupdatePermissiosPageRoutingModule,
    SharedModule,
    ToastrModule.forRoot(),
    MatExpansionModule,
    MatSlideToggleModule,
    MatInputModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule
  ],
  declarations: [
    BulkupdatePermissiosPage
  ]
})
export class UpdateManifestPageModule {
}

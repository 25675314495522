<ion-header>
  <ion-toolbar>
    <ion-title>Error</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div>
    <span>Error Message: {{message}}</span>
  </div>
  <br>
  <div *ngIf="errorType == 'SYSTEM_ERROR'">
    <span>Context: </span>
    <pre>{{context | json}}</pre>
  </div>
  <br>
  <div *ngIf="errorType == 'SYSTEM_ERROR'">
    <span>Error Code: {{errorCode}}</span>
  </div>
</ion-content>
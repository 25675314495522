<ion-content class="ion-padding">
    <ngx-spinner type="ball-beat" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff">
        <p style="color: white">Loading...</p>
    </ngx-spinner>
    <p><a [routerLink]="'/home'">Home</a></p>
    <div class="neo-content">
        <h1>Bulk update user permissions</h1>
        <div class="flex-row-even form-content-wrapper">
            <form class="box bulkupdate-form" [formGroup]="userForm"
                (ngSubmit)="submitBulkUpdateRequest()" autocomplete=off>
                <div class="table-options-wrapper" *ngIf="applications.length">
                    <mat-form-field>
                        <mat-label>Choose an application</mat-label>
                        <mat-select formControlName="selectedApplication" required (blur)="setFieldTouched('selectedApplication')"
                            (selectionChange)="getApplicationRoles()">
                            <mat-option *ngFor="let application of applications" [value]="application.value">
                                {{ application.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <span id="DealerIdsError" class="error" *ngIf="userForm?.errors?.['selectedApplication']">{{
                    userForm.errors?.['selectedApplication']
                    }}</span>

                <div class="table-options-wrapper">
                    <mat-form-field>
                        <mat-label>Choose an role to update</mat-label>
                        <mat-select formControlName="selectedRole" required
                            (blur)="setFieldTouched('selectedRole')">
                            <mat-option *ngFor="let applicationRole of applicationRoles"
                                [value]="applicationRole.value">
                                {{ applicationRole.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <span id="DealerIdsError" class="error" *ngIf="userForm?.errors?.['selectedRole']">{{
                    userForm.errors?.['selectedRole']
                    }}</span>

                <div class="table-options-wrapper">
                    <mat-form-field>
                        <mat-label>Choose an operation</mat-label>
                        <mat-select formControlName="selectedOperation" required 
                            (blur)="setFieldTouched('selectedOperation')">
                            <mat-option [value]="'add'">add</mat-option>
                            <mat-option [value]="'remove'">remove</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <span id="DealerIdsError" class="error" *ngIf="userForm?.errors?.['selectedOperation']">{{
                    userForm.errors?.['selectedOperation']
                    }}</span>

                <div class="custom-checkbox">
                    <input formControlName="updateAll" type="checkbox" name="update all" id="updateall-checkbox"
                    (blur)="setFieldTouched('updateAll')">
                    <label for="updateall-checkbox">update all</label>
                </div>
                <span id="updateAllError" class="error" *ngIf="userForm?.errors !== undefined && userForm?.errors?.['updateAll']">{{
                    userForm.errors?.['updateAll']
                    }}</span>

                <div>
                    <label for="DealerIds">Dealer Id Lists(',' separated)</label>
                    <input (blur)="setFieldTouched('DealerIds')" formControlName="DealerIds" type="text" id="DealerIds">

                    <span id="DealerIdsError" class="error" *ngIf="userForm?.errors?.['DealerIds']">{{
                        userForm.errors?.['DealerIds']
                        }}</span>
                </div>

                <button mat-raised-button color="primary" type="submit" id="saveUserButton" class="btn"
                    [ngClass]="{'secondary inactive': userForm.invalid}"
                    [disabled]="!userForm.valid">Submit</button>
            </form>
            <div *ngIf="Requests.length>0" class="bulkupdate-form box verticalScroll">
                <div *ngFor="let request of Requests" class="box">
                    <div>ID: {{request.id}}</div>
                    <div>Message: {{request.message}}</div>
                </div>
            </div>
        </div>
    </div>
</ion-content>
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { UserService } from '../services/user.service';
import { Observable, map } from 'rxjs';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';

interface ApplicationDisplayName {
  'afcd': string;
  'auctionportal': string;
  'super-admin': string;
  appName?: string;
}

@Component({
  selector: 'app-bulkupdate-permissios',
  templateUrl: './bulkupdate-permissios.page.html',
  styleUrls: ['./bulkupdate-permissios.page.css']
})
export class BulkupdatePermissiosPage implements OnInit {
  canBulkUpdate$!: Observable<boolean | undefined>;
  applications: any = [];
  applicationRoles: {value: string, name: string}[] = [];
  updateAll: boolean = false;
  DealerIds: string = '';
  Requests: any[] = [];
  userForm: FormGroup;
  applicationDisplayName: ApplicationDisplayName = {
    'afcd': 'AFCD Users',
    'auctionportal': 'Auction Portal Users',
    'super-admin': 'Superadmin (NEO) Users'
  }

  constructor(
    public userService: UserService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userForm = this.formBuilder.group({
      selectedApplication: [''],
      selectedRole: [{value: '', disabled: this.applicationRoles.length < 1}],
      updateAll: [false],
      DealerIds: [''],
      selectedOperation: ['']
    },
      { validator: this.formValidator }  as AbstractControlOptions);
  }

  checkIfTouched(controller: AbstractControl<any, any> | null) {
    return (controller?.dirty || controller?.touched)
  }

  checkController(controller: AbstractControl<any, any> | null) {
    return controller && !controller.value
  }

  setFieldTouched(controllerName: string) {
    const controller = this.userForm.get(controllerName);
    controller?.markAsTouched();
    this.userForm.updateValueAndValidity();
  }

  formValidator = (group: FormGroup): { [key: string]: any } | null => {
    const updateAllControl = group.get('updateAll');
    const DealerIdsControl = group.get('DealerIds');
    const selectedApplicationControl = group.get('selectedApplication');
    const selectedRoleControl = group.get('selectedRole');
    const selectedOperationControl = group.get('selectedOperation');

    const errors: any = {};
    if (
      this.checkController(updateAllControl) && this.checkController(DealerIdsControl) && (this.checkIfTouched(updateAllControl) || this.checkIfTouched(DealerIdsControl))
    ) {
      errors.DealerIds = 'Update all/ Dealer id list is required.';
      errors.updateAll = 'Update all/ Dealer id list is required.';
    }

    if (
      this.checkController(selectedApplicationControl) && this.checkIfTouched(selectedApplicationControl)) {
      errors.selectedApplication = 'Application id is required.';
    }

    if (
      this.checkController(selectedRoleControl) && this.checkIfTouched(selectedRoleControl)
    ) {
      errors.selectedRole = 'role for application is required.';
    }

    if (
      this.checkController(selectedOperationControl) && this.checkIfTouched(selectedOperationControl)
    ) {
      errors.selectedOperation = 'operation is required.';
    }

    //this checks if string only contains numbers, *, and ,
    const regex = /^([\d*]+,)*[\d*]+/
    if (DealerIdsControl?.value && !(regex.test(DealerIdsControl.value))) {
      errors.DealerIds = 'invalid details. please use numbers, *, and , to separate multiple dealers'
    }

    return errors;
  }

  ngOnInit(): void {
    this.canBulkUpdate();
    this.getApplication();
  }

  canBulkUpdate() {
    this.canBulkUpdate$ = this.userService.ready$.pipe(
      map(ready => {
        if (ready) {
          return this.userService.hasTopLevelPermission('update-user');
        }
        return false;
      })
    )

    this.canBulkUpdate$.subscribe(permission => {
      //if userService is not ready this will now redirect to home directly.
      if (permission === false) {
        this.router.navigate(['/home']);
      }
    })
  }

  getApplication() {
    this.spinner.show();
    this.userService
      .getApplications()
      .subscribe((data: any) => {
        this.updateApplicationName(data.applications);
        this.spinner.hide();
      });
  }

  updateApplicationName(applications: string[] = []) {
    for (const appName of applications) {
      if (appName in this.applicationDisplayName) {
        this.applications.push({
          name: (this.applicationDisplayName as any)[appName],
          value: appName
        })
      }
    }
  }

  getApplicationRoles() {
    this.spinner.show();
    this.userService
      .getApplicationRoles(this.userForm.get('selectedApplication')?.value)
      .subscribe((data: any) => {
        this.applicationRoles = data.roles.map((role: string) => {
          return {
            name: role.split('-').join(' '),
            value: role
          }
        })
        if(this.applicationRoles.length){
          this.userForm.get('selectedRole')?.enable();
        }
        this.spinner.hide();
      });
  }

  submitBulkUpdateRequest() {
    let payload: {
      operation: string;
      appId: string;
      permission: string;
      updateAll?: boolean;
      dealerList?: string[];
    } = {
      operation: this.userForm.get("selectedOperation")?.value,
      appId: this.userForm.get("selectedApplication")?.value,
      permission: this.userForm.get("selectedRole")?.value,
      updateAll: false,
      dealerList: [""],
    };
    if (this.userForm.get("DealerIds")?.value) {
      payload.dealerList = this.userForm.get("DealerIds")?.value.split(",");
      delete payload.updateAll;
    } else {
      payload.updateAll = this.userForm.get("updateAll")?.value;
      delete payload.dealerList;
    }

    this.userService.submitBulkUpdatePermissionRequest(payload)
      .subscribe({
        next: (data) => {
          this.Requests.push(data);
          this.toastr.success(data.message);
        },
        error: (err) => {
          this.toastr.error(err.error);
        }
      });
  }
}
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControlOptions } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router) { }
  userSearchForm!: FormGroup;
  userSearchFormErrors: any = {
    parent: '',
    dealerId: '',
    lastName: '',
    emailAddress: ''
  };
  userSearchValidationMessages: any = {
    parent: {
      mandatory: 'Need at least 1 search criteria.'
    },
    dealerId: {
      pattern: 'Invalid format, please enter at least 3 numeric characters.'
    },
    lastName: {
      minlength: 'Invalid format, please enter at least 2 characters.',
      maxlength: 'Invalid format, the max length for this field is 100 characters.'
    },
    emailAddress: {
      maxlength: 'Invalid format, the max length for this field is 100 characters.',
      email: 'Invalid format, Not a valid email address.'
    },
    
  };
  ngOnInit() {
    this.userSearchForm = this.formBuilder.group({
      emailAddress: ['', [Validators.maxLength(100), Validators.email]],
      dealerId: ['', [Validators.pattern('[0-9]{3,37}') ]],
      lastName: ['', [Validators.minLength(2), Validators.maxLength(100)]]
    }, { validator: this.userSearchValidator } as AbstractControlOptions);
  }

  userSearchValidator(group: any): { [key: string]: any } | null {
    const dealerId = group.controls['dealerId'].value;
    const emailAddress = group.controls['emailAddress'].value;
    const lastName = group.controls['lastName'].value;
    if(dealerId  || emailAddress || lastName){
      return null;
    }
    return {mandatory: true};
  };

  omitAlphaSpecialChar(event: any): boolean {
    const k = event.charCode;
    const minCharCode = 48;
    const maxCharCode = 57;
    const enterKey = 13;
    return (k >= +minCharCode && k <= +maxCharCode) || k == enterKey;
  }

  searchUsers() {
    if (this.userSearchForm.valid) {
      const dealerId = this.userSearchForm.get('dealerId')?.value;
      const lastName = this.userSearchForm.get('lastName')?.value;
      const emailAddress = this.userSearchForm.get('emailAddress')?.value;
      const queryParams: any = {};
      if(dealerId){
        queryParams.dealerId = dealerId;
        queryParams.application = 'afcd';
      }
      if(lastName){
        queryParams.lastName = lastName;
      }
      if(emailAddress){
        queryParams.emailAddress = emailAddress;
      }
      this.router.navigate(['/user-search-results'], { queryParams });
      
    }
  }

  setParentFormErrors(group: FormGroup){
    this.userSearchFormErrors['parent'] = '';
    if (group.errors) {
      const messages: any = this.userSearchValidationMessages['parent'];
      for (const errkey in group.errors) {
        if (errkey) {
          this.userSearchFormErrors['parent'] = messages[errkey];
        }
      }
    }
  }
  

  userSearchValidationErrors(group: FormGroup): void {
    Object.keys(group.controls).forEach((key: string) => {
      const absControl = group.get(key);
      this.userSearchFormErrors[key] = '';
      // Error messages at child/field level
      if (absControl && !absControl.valid && (absControl.touched || absControl.dirty ||
        (absControl.value !== '' && absControl.value !== null))) {
        const messages = this.userSearchValidationMessages[key];
        for (const errkey in absControl.errors) {
          if (errkey) {
            this.userSearchFormErrors[key] = messages[errkey];
          }
        }
      }
    });

    // Error messages at the form parent/form level
    this.setParentFormErrors(group);
  }
}

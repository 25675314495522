import { Injectable, ErrorHandler } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {

  handleError(error: Error): void {
    console.log('Error Service handling error');
    console.log(error);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.page.html',
  styleUrls: ['./error.page.scss'],
})
export class ErrorPage implements OnInit {

  message: string = '';
  context: any;
  errorCode?: string;
  errorType!: string;

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.message = params['message'] ?
        params['message']
        : 'An unexpected error occurred. Contact AFC Administrator';

      try {
        this.context = JSON.parse(params['context']);
      } catch (err) {
        this.context = { 'context': 'none' };
      }

      this.errorCode = params['errorCode'] ?
        params['errorCode']
        : 'none';

      this.errorType = params['errorType'] ?
        params['errorType']
        : 'SYSTEM_ERROR';
    });

  }

}

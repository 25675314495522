import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import localeCa from '@angular/common/locales/ca';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OktaAuthGuard, OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import {OktaAuth,OAuthResponseType, OAuthResponseMode} from '@okta/okta-auth-js';
import { NgxPaginationModule } from 'ngx-pagination';
import { ErrorService } from 'src/app/services/error.service';
import { MockModule } from 'src/app/util/mock.module';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorPageModule } from './error/error.module';
import { HomePageModule } from './home/home.module';
import { LoggedOutPageModule } from './logged-out/logged-out.module';
import { PaginationModule } from './pagination/pagination.module';

import { ToastrModule } from 'ngx-toastr';
import { AppConstants } from './app.constant';
import { UpdateManifestPageModule } from './bulkupdate-permissios/bulkupdate-permissios.module';
 

const oktaConfig = {
  clientId: environment.oktaClientId,
  issuer: environment.oktaIssuerURL,
  redirectUri: environment.oktaRedirectURL,
  scopes: ['openid'],
  responseType: ['code'] as OAuthResponseType | OAuthResponseType[],
  responseMode:'query' as OAuthResponseMode,
  pkce: true,
  tokenManager: {
    expireEarlySeconds: 290,
    autoRenew: true
  },
  onSessionExpired: () => {
    console.log('User session expired. Redirect to login page');
    oktaAuth.tokenManager.clear();
    oktaAuth.token.getWithRedirect();
  }
};

const oktaAuth: OktaAuth = new OktaAuth(oktaConfig);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    OktaAuthModule,
    IonicModule.forRoot(),
    HomePageModule,
    LoggedOutPageModule,
    ErrorPageModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxPaginationModule,
    PaginationModule,
    ToastrModule.forRoot(AppConstants.toastrConfig),
    MockModule,
    UpdateManifestPageModule],
  providers: [
    provideHttpClient(),
    { provide: OKTA_CONFIG, useValue: {oktaAuth}  },
    { provide: ErrorHandler, useClass: ErrorService},
    OktaAuthGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}


registerLocaleData(localeCa, 'ca');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEs, 'es');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/language/');
}

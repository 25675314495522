import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dealer-search',
  templateUrl: './dealer-search.component.html',
  styleUrls: ['./dealer-search.component.scss'],
})
export class DealerSearchComponent implements OnInit {

  dealerSearchForm!: FormGroup;
  formErrors: any = {
    parent: '',
    dealerId: '',
    dealerName: ''
  };
  validationMessages: any = {
    parent: {
      exclusiveOr: 'Need either DealerId or Dealership Name exclusively.'
    },
    dealerId: {
      pattern: 'Invalid format, please enter at least 3 numeric characters.'
    },
    dealerName: {
      minlength: 'Invalid format, please enter at least 3 characters.',
      maxlength: 'Invalid format, the max length for this field is 80 characters.'
    }
  };
  
  constructor(
    private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.dealerSearchForm = this.formBuilder.group({
      dealerId: ['', [Validators.pattern('[0-9]{3,37}') ]],
      dealerName: ['', [Validators.minLength(3), Validators.maxLength(80)]]
    }, { validator: this.dealerSearchExclusiveOr } as AbstractControlOptions);
    
  }

  dealerSearchExclusiveOr(group: FormGroup): { [key: string]: any } | null {
    const dealerId = group.controls['dealerId'].value;
    const dealerName = group.controls['dealerName'].value;
    if ((dealerId !== '' && dealerName === '') || (dealerId === '' && dealerName !== '')) {
      return null;
    }
    return { exclusiveOr: true };
  }

  omitAlphaSpecialChar(event: any): boolean {
    const k = event.charCode;
    const minCharCode = 48;
    const maxCharCode = 57;
    const enterKey = 13;
    return (k >= +minCharCode && k <= +maxCharCode) || k == enterKey;
  }

  searchDealers() {
    if (this.dealerSearchForm.valid) {
      const dealerId = this.dealerSearchForm.get('dealerId')?.value;
      const dealerName = this.dealerSearchForm.get('dealerName')?.value;
      this.router.navigate(['/dealer-search-results'], { queryParams: { dealerId, dealerName } });
    }
  }

  setParentFormErrors(group: FormGroup){
    this.formErrors['parent'] = '';
    if (group.errors) {
      const messages: any = this.validationMessages['parent'];
      for (const errkey in group.errors) {
        if (errkey) {
          this.formErrors['parent'] = messages[errkey];
        }
      }
    }
  }
  
  validationErrors(group: FormGroup): void {
    Object.keys(group.controls).forEach((key: string) => {
      const absControl = group.get(key);
      this.formErrors[key] = '';
      // Error messages at child/field level
      if (absControl && !absControl.valid && (absControl.touched || absControl.dirty ||
        (absControl.value !== '' && absControl.value !== null))) {
        const messages = this.validationMessages[key];
        for (const errkey in absControl.errors) {
          if (errkey) {
            this.formErrors[key] = messages[errkey];
          }
        }
      }
    });

    // Error messages at the form parent/form level
    this.setParentFormErrors(group);
  }
}

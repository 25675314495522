import { Component, Inject, OnInit } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Component({
  template: `Redirecting..`
})
export class LoginRedirectComponent implements OnInit {

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) { }

  ngOnInit() {
    this.oktaAuth.signInWithRedirect({ originalUri:'/home'});
  }

}

import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  env!: string;

   constructor() {
    this.initializeApp();
  }
  
  initializeApp() {
    this.env = `${environment.environment}`.toLocaleUpperCase();
  }
}
